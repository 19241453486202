.pills {
  display: flex;
  margin-right: 2rem;

  .pill {
    display: flex;

    &:not(:last-of-type) {
      margin-right: 1.5rem; }

    &__left {
      border-radius: 1rem 0 0 1rem;
      padding: 0 1rem;
      display: flex;
      justify-content: center;
      align-items: center;

      &.default {
        background-color: $btn-primary; }

      &.red {
        background-color: $item-light-red; }

      &.green {
        background-color: $item-green; } }

    &__right {
      background-color: #232740;
      padding: 1rem 0.6rem;
      border-radius: 0 1rem 1rem 0;
      flex: 1;
      font-size: 1.2rem;
      justify-content: center; } } }

