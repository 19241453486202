@-webkit-keyframes autofill {
  0%,100% {
    color: #fff;
    background-color: $color-dark-blue-100; } }

input:-webkit-autofill {
  -webkit-animation-delay: 1s;
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
  font-size: 1.4rem; }

::placeholder {
  font-weight: 300;
  color: #979797 !important; }

.form-inline {
  .form-control {
    margin-bottom: 0;
    margin-right: 1rem; } }

.form-inline {
  span {
    margin: {
      top: 0;
      right: 1rem;
      bottom: 0;
      left: 0; } } }

.form-control {
  color: #ffffff;
  font-size: 1.4rem;
  border: {
    radius: 0;
    width: 0;
    bottom: 0.1rem solid $color-border; }
  background: {
    color: $color-dark-blue-100; }
  font-weight: 300;
  height: 4rem;
  margin-bottom: 1rem;
  border-radius: $border-radius;

  &:focus {
    box-shadow: none;
    background-color: $color-dark-blue-100;
    border-bottom-color: #23BA99;
    color: #fff; }

  &.error {
    box-shadow: none;
    background-color: $color-dark-blue-100;
    border-bottom-color: #B11414;
    color: #fff; }

  // if there is an icon in the form group use these classes
  &.form-control--has-icon-left {
    padding-left: 3rem; }

  &.form-control--has-icon-right {
    padding-right: 3rem; } }


// alternative 'outline' form input for darker background areas
.form-control-outline {
  background-color: transparent;
  border: .1rem solid $color-border;
  box-shadow: 0 .5rem .5rem 0 rgba(0, 0, 0, 0.18);

  &:focus {
    background: transparent;
    border: .1rem solid $color-border;
    border-bottom: .1rem solid $color-green;
    box-shadow: 0 .5rem .5rem 0 rgba(0, 0, 0, 0.18); } }

.input-group {
  .form-control {
    margin-bottom: 0; } }

.input-group-prepend {
  color: #fff;
  padding: 0 .5rem;
  font-size: 1.4rem;
  background-color: #30385a;
  margin-top: .1rem;
  margin-bottom: .1rem; }

.input-group-text {
  font-size: 1.8rem;
  color: #fff;
  border: 0;
  background-color: transparent;
  font-weight: 300; }

.form-tags-container {
  background-color: transparent !important;
  border: 0 !important;
  border-bottom: 0rem !important;

  span {
    color: #ffffff !important; }

  &.form-tags-border {
    border: 1px solid #171925 !important; }

  &.form-remove-padding {
    height: 28px !important;
    min-height: 0 !important;

    .select2-selection__rendered {
      padding: 0 !important; }

    .select2-counter {
      position: absolute;
      left: 1rem;
      top: 1rem;
      transition: 1s; } } }

.tms-select {
  padding: .5rem;
  border-radius: .5rem;
  border: 0;
  border-bottom: .1rem solid $color-border;
  font-weight: 300;
  font-size: 1.4rem;
  margin-bottom: .13rem;
  width: 100% !important; }

.select2-selection__rendered {
  padding-left: .3rem !important; }

.select2-container--default .select2-selection--single {
  border-bottom-width: 0 !important;

  &:focus {
    outline: 0; }

 }  // hide certain things/styles for tag selects
.form-tags-container {
  border-radius: .5rem;
  background: transparent;
  border-bottom: 0 !important;

  .select2-selection__rendered {
    padding: 0; }

  .select2-dropdown-icon {
    display: none; }

  .select2-selection {
    border: 0 !important; } }

.sidebar-tags {
  .select2-container {
    border-bottom: 0;
    padding: 0; }

  .select2-dropdown-icon {
    display: none; } }

.select2-selection--multiple {
  .select2-dropdown-icon {
    display: none; } }

// area showing the results
.select2-dropdown {
  background-color: $dark-background-color !important;
  box-shadow: 0px 2px 3px -2px rgba(0, 0, 0, 0.75);
  border: 0;
  border-bottom: .1rem solid $color-border;
  margin-top: 0rem;
  border-bottom-left-radius: .3rem;
  border-bottom-right-radius: .3rem;

  &:b[role="presentation"] {
    background: red; }

  input {
    background: transparent;
    color: $white; } }

.select2-results__option {
  transition: background .3s ease-in-out;
  padding: .7rem 0 .7rem 1.2rem; }

.select2-results__option--highlighted[aria-selected] {
  background-color: $color-dark-blue !important;
  border: 0;
  padding: .7rem 0 .7rem 1.2rem; }

.select2-results__option[aria-selected=true] {
  background-color: $color-dark-blue !important;
  color: $color-white !important;
  border-top: .1rem solid $color-border;
  border-bottom: .1rem solid $color-border; }

.select2-search__field {
  outline: 0;
  border-color: $color-border !important; }

.select2-dropdown-icon {
  font-size: 2rem;
  position: absolute;
  right: 2rem;
  top: 0.5rem;
  transition: all .2s ease-in-out;
  pointer-events: none; }

.select2-container--open {
  .select2-dropdown-icon {
    transform: rotate(180deg); } }

// style disabled input
.select2-container--disabled {
  .form-tags-container span {
    color: #888 !important; } }

label {
  margin-top: 0;
  font-size: 1.2rem;
  color: #ffffff80; }

.form-group {
  margin-bottom: 1rem;

  // icons inside form groups

  .form-control-icon {
    color: #3B4A78;
    font-size: 1.5rem;
    position: absolute;

    &.form-control-icon--right {}
    top: 2.3rem;
    right: 2rem; } }

// Checkboxes
.custom-control {
  float: left;
  margin-right: 1rem;
  padding-left: 1.8rem; }

.custom-control-label {
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #fff; }

.form-check-label {
  color: #fff;
  font-size: 1.4rem; }

.custom-control-label:before {
  top: 0;
  width: 1.8rem;
  height: 1.8rem;
  border-color: #979797;
  transition-delay: 0s;
  left: -1.8rem; }

.custom-control-label:after {
  top: -0.2rem;
  width: 2.2rem;
  height: 2.2rem;
  left: -2rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition-delay: 0s; }

.custom-control-input {
  position: inherit;

  &:checked ~ .custom-control-label:before {
    color: $color-white;
    border-color: $color-purple;
    background-color: $color-purple; }

  &:focus:not(:checked) ~ .custom-control-label:before {
    border-color: $color-white; }

  &:not(:disabled):active ~ .custom-control-label:before {
    color: $color-white;
    background-color: transparent;
    border-color: $color-white; }

  &:not(:disabled):checked ~ .custom-control-label:before {
    color: $color-white;
    border-color: $color-purple;
    background-color: $color-purple; } }

.btn {
   border-radius: .5rem;
   font-weight: 700; }

.btn-primary {
   background-color: $btn-primary; }

.btn-secondary {
   background-color: $btn-secondary;
   border: .1rem solid $btn-secondary; }

.input-base__option + .custom-checkbox .custom-control-label {
  &:before {
    background-color: #4C84FF !important;
    border-color: #4C84FF !important; } }

.custom-switch {
   padding-left: 4rem;

   .custom-control-input[type=checkbox] {
      display: none; }

   .custom-control-input {
      &:active {
         border-color: #3B4A78;
         background-color: #3B4A78; } }

   .custom-control-input:checked ~ .custom-control-label {
      &:after {
         transform: translateX(1.2rem); } }

   .custom-control-input:checked ~ .custom-control-label,
   .custom-control-input:disabled:checked ~ .custom-control-label {
      &:before {
         background-color: #22B69E;
         border-color: #22B69E; } }

   .custom-control-input:not(:checked):focus ~ .custom-control-label,
   .custom-control-input:active ~ .custom-control-label,
   .custom-control-input[disabled] ~ .custom-control-label,
   .custom-control-input:disabled ~ .custom-control-label,
   .custom-control-label {
      &:before {
         border-radius: 10rem;
         width: 3rem;
         left: -3.5rem;
         border-color: #3B4A78;
         background-color: #3B4A78; }

      &:after {
         top: calc(0.1rem + 2px);
         left: calc(-3.4rem + 2px);
         width: calc(1.55rem - 4px);
         height: calc(1.55rem - 4px);
         border-radius: 10rem; } }

   &.error {
      .custom-control-label {
         color: $item-red;

         &:before {
            border: 1px solid $item-red !important; } } } }

.section {
  display: flex;
  flex-direction: column;

  &--icon {
    justify-content: center;
    position: relative; }

  .row {
    margin-left: 0;
    margin-right: 0; }

  .button-bar {
    position: relative;

    button {
      position: absolute;
      top: -20px;
      right: 0; }

    .button-bar__right {
      position: absolute;
      top: 0;
      right: 1.5rem;
      cursor: pointer; } }

  .row .input-base + .input-base {
    margin-left: 1rem; }

  .first-in-row {
    padding-right: 0.5rem; }

  .last-in-row {
    padding-left: 0.5rem; }

  .error {
    border-color: $item-red;

    .reason {
      color: $item-red; }

    .reason ~ label:not(.reason) {
      top: 18px; } } }

.input-base {
  display: flex;
  flex-direction: row;
  border: 1px solid #374171;
  position: relative;
  margin-bottom: 1rem;
  border-radius: 5px !important;
  background-color: #232740;
  flex: 1 0;

  &.no-flex {
    flex: unset; }

  &__dummy-input {
    outline: none!important; }

  &.error {
    border: 1px solid $item-red; }

  .tail-select {
    margin-bottom: 0.5rem; }

  .input-badge {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0.6rem;
    right: 0.6rem;
    border-radius: 10rem;
    font-size: 0.8rem;
    min-width: 1.6rem;
    min-height: 1.6rem;
    border: 1px solid $main-bg-lighter;

    &.input-badge--red {
      background-color: $item-light-red; }

    &.input-badge--green {
      background-color: $item-green; }

    &.input-badge--blue {
      background-color: $item-lighter-blue; } }

  &:not(.input-base--on-fly-search) .select2-container {
    width: 100% !important;

    .select2-selection--single .select2-selection__rendered {
      padding-right: 3.5rem; } }

  .custom-checkbox {
    display: none; }

  &.column {
    flex-direction: column; }

  &.highlight {
    border-color: #FFFFFF80;
    background-color: #3B4A78;

    .custom-checkbox {
      display: block; } }

  &__list {
    height: 18.5rem;
    overflow: auto;
    margin-top: 2.5rem;
    padding: 0rem 1rem;
    width: 100%;

    &--item {
      margin-bottom: 1rem; } }

  &__custom-label {
    float: unset;
    position: relative;
    height: 3rem;
    display: block !important;

    &--label {
      top: .5rem !important;
      left: 2rem !important;
      text-indent: 1rem; }

    &--input {
      position: absolute !important;
      left: -1rem !important;
      top: -1rem !important;
      z-index: unset !important; } }

  &:not(.dropdown-bullet) .dropdown-item:before {
    display: none; }

  &.no-bg {
    border: 0;
    background: transparent; }

  &.disabled {
    background: transparent;
    pointer-events: none;

    .tail {
      display: none; }

    .select-label:after {
      content: ""; } }

  .input-base-container {
    width: 100%;
    margin-top: 2rem;
    margin-left: 1rem;
    padding-right: 1rem;
    overflow: hidden;

    div {
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap; } }

  label {
    position: absolute;
    top: 12px;
    left: 10px;
    padding-right: 10px;
    font-size: 1.4rem;
    transition: all 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: auto;
    max-width: 100%;

    &.shrink {
      font-size: 1rem;
      top: 5px; } }

  input:focus, input:disabled, input[readonly],
  input, textarea, select, option, .alt-input {
    display: block;
    background: transparent;
    border: 0;
    border-radius: 0;
    margin: 2rem 0 .5rem 1rem;
    font-size: 1.4rem;
    color: #FFFFFF;
    font-weight: 500; }

  input ~ label, textarea ~ label {
    cursor: text;
    pointer-events: none; }

  input, select, option, .alt-input {
    height: 2rem;
    padding: 0; }

  input[type=checkbox] {
    height: 1.5rem;
    width: 2rem;
    opacity: 1 !important; }

  input[readonly] {
    color: #999; }

  textarea {
    padding: 0;

    &:focus {
      background-color: transparent; } }

  select {
    border: 0;
    box-shadow: none;
    margin: 2rem 0 0 0;
    padding: 0;

    option {
      background-color: #232740; } }

  &.input-base-button {
    height: 4.7rem;
    cursor: pointer;
    width: 4.5rem;
    min-width: 4.5rem;
    flex: unset;
    font-size: 2rem;

    .input-button {
      flex: 1;
      height: 100%; } }

  &.input-base-small {
    width: 4.5rem;
    height: 4.7rem; }

  .append {
    align-self: center;
    background: none;
    color: #374171;
    margin-top: .2rem;
    margin-right: 2rem;
    font-size: 1.6rem; }

  .input-base__option {
    padding: 1rem;
    flex: 1; }

  .append-edit {
    align-self: flex-start;
    background: none;
    color: #374171;
    margin-top: 1rem;
    margin-right: 0.5rem;
    font-size: 1.2rem;
    color: #FFFFFF;
    cursor: pointer; }

  .append-delete {
    align-self: flex-start;
    background: none;
    color: white;
    margin-top: 1rem;
    margin-right: 1rem;
    margin-left: 0.5rem;
    font-size: 1.2rem;
    cursor: pointer; }

  .label-inner {
    font-size: 1.4rem !important; } }

.input-options--prefix {
   background-color: $item-blue;
   border-top: 1px solid $border;
   border-left: 1px solid $border;
   border-bottom: 1px solid $border;
   margin: 0 0 1rem 0;
   padding: 0 1.7rem;
   border-radius: 5px 0 0 5px;
   display: flex;
   justify-content: center;
   align-items: center;
   min-width: 0;
   font-size: 1.8rem;

   & + .input-base {
      border-radius: 0 5px 5px 0 !important; } }

.d-flex-col {
   display: flex;
   flex-direction: column; }

.flex-1 {
  flex: 1; }

.flex-2 {
  flex: 2; }

.flex-10 {
  flex: 10; }

.flex-unset {
  flex: unset !important; }


.table {
   display: table; }

.table-row {
   display: table-row; }



.floating-icon {
  position: absolute;
  font-size: 5rem;
  color: $item-light-blue;
  left: -0.25rem; }


.summary {
  padding: 1rem 0rem;

  &--line {
    margin-bottom: 0.5rem;
    padding-bottom: 0.75rem;
    border-bottom: 0.2rem solid $item-light-blue;
    margin-bottom: 1rem;
    font-size: 1.2rem;
    display: flex;
    justify-content: space-between;

    &:not(.summary--header) {
      font-size: 1.1rem;
      border-bottom: 0.1rem dotted $item-light-blue; }

    &:last-of-type {
      margin-bottom: 0rem;
      border-bottom: 0.2rem solid $item-light-blue; } }

  &--label {
    color: $item-light-gray; } }

.drag-area-highlight {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  z-index: 998;
  padding: 3rem;
  background-color: $item-light-blue;
  display: flex;
  transition: all 0.2s ease-in-out; }

.drag-area-style {
  border-radius: 2rem;
  border: 5px dashed $item-dark-blue;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center; }


.dragging-file {
    .drag-area-highlight {
      opacity: 1; } }

.drop-zone {
  position: relative; }

.justify-self-center {
  justify-self: center !important; }

.ag-grid-searchj-buffer {
  width: 25rem; }

.min-width-unset {
  min-width: unset !important; }

.overflow-visible {
  overflow: visible !important; }

.font-2 {
  font-size: 2rem; }

.font-3 {
  font-size: 3rem; }

.font-4 {
  font-size: 4rem; }


