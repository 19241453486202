html, body {
  height: 100%; }

body {
  background-color: $color-dark-blue; }

textarea {
  resize: none; }

.page-container {
  margin-top: 10rem; }

hr {
  background-color: #ffffff20;
  margin: 2rem 0; }

.styled-data-row {
  background-color: $color-border;
  padding: .5rem;
  margin-bottom: .5rem;
  font-size: 1.4rem; }

.js-site-dropdown-data-form {
  border: 1px solid $color-border;
  padding: 1rem;
  margin-bottom: 1rem; }

ul {
  padding: 0;
  margin: 0;
  list-style: none; }

a {
  color: #fff;
  text-decoration: none;
  background-color: transparent;

  &:hover {
    text-decoration: none;
    transition: .3s;
    color: #fff; } }

.tooltip {
  pointer-events: none; }

/** SCROLL BAR **/
::-webkit-scrollbar {
  width: 0.5rem;
  height: 1rem;
  border-radius: 0.8rem; }

::-webkit-scrollbar-track {
  background: $scroll-bar-background; }

::-webkit-scrollbar-thumb {
  background: $scroll-bar;
  border-radius: 5px; }

::-webkit-scrollbar-thumb:hover {
  background: $scroll-bar;
  cursor: pointer; }

/** Firefox allows limited customisations **/
html {
  scrollbar-color: $scroll-bar $scroll-bar-background;
  scrollbar-width: thin; }

//autofill overwrite
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: 0 0 0px 1000px $input-background inset !important;
  transition: background-color 5000s ease-in-out 0s !important;
  font-weight: 500;
  caret-color: #ffffff; }

/* Footer Bar */
.footer-bar {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem; }

.job-booking__credit-limit {
  border: 1px solid #3B4A78;
  padding: 6px;
  font-size: 14px;
  width: 100%;
  max-height: 85px;
  overflow-x: auto; }

.job-booking__account-notes {
  border: 1px solid #3B4A78;
  padding: 6px;
  font-size: 14px;
  width: 100%;
  max-height: 85px;
  overflow-x: auto; }

.ag-hide-group {
  .ag-group-contracted {
    opacity: 0 !important;
    pointer-events: none !important; } }
