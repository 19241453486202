.commenting {
  position: relative;
  margin-right: 1rem;

  &--cheat-sheet {
    position: absolute;
    top: 2rem;
    left: -100%;
    z-index: 9999;
    background: $cyan;
    box-shadow: $card-shadow;
    padding: 1rem;
    width: 40rem;

    &::before {
      content: "";
      height: 0;
      position: absolute;
      width: 0;
      border: 8px solid transparent;
      border-left-color: $cyan;
      top: 2rem;
      right: -1.5rem; }

    .content {
      * {
        color: black !important; }

      li {
        font-size: 1.2rem; }

      h5 {
        margin-bottom: 5px !important; } } }

  &--tags {
    background: $item-blue-tag;
    padding: 0.5rem;
    border-radius: 0.5rem; }

  &__button {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover,
    &.active {
      cursor: pointer;
      &:not([disabled]) {
        background: $item-blue; } } }

  &__popup {
    position: absolute;
    right: 0rem;
    top: 5rem;
    height: 50rem;
    z-index: 500;

    .popup-card {
      max-width: 15rem;
      height: 100%;

      .popup-card__header {
        height: 4rem; }

      .popup-card__body {
        padding: 2rem 3rem;
        height: 100%;
        display: flex;
        flex-flow: column; }

      .popup-card__body-sperator-block {
        flex: 0 1 auto; } }

    .input-area {
      margin-bottom: 1rem; }

    .comment-options {
      display: none;

      &.open {
        display: block; } }

    .comment-search-results-counter {
      opacity: 0.4;
      margin-bottom: 1rem;
      padding: 0.5rem;
      text-align: right;
      display: none; } }

  textarea {
    height: 2rem;
    resize: none;
    box-sizing: border-box;
    width: 100%;
    overflow: hidden;
    transition: 0.5s;

    &:focus {
      margin: 2rem 0 .5rem 1rem;
      height: 100px !important;
      margin-bottom: 10px;
      transition: 0.5s; } }

  .comments-list {
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: hidden;

    .comment {
      font-size: 1.2rem;
      margin-bottom: 0.1rem;
      border-bottom: $line-divider;
      padding-bottom: 1rem;
      padding-left: 1rem;
      padding-right: 1rem;

      .header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;
        font-size: inherit;

        span {
          font-size: inherit;

          span {
            color: inherit !important; } } }

      .content {
        margin-bottom: 1rem;
        word-break: break-all; }

      .footer {
        font-size: 12px;
        color: #7C9BEF;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .comment-replies-btn {
          font-size: inherit;
          display: inline-block;

          i {
            margin-left: 0.5rem;
            transition: .5s;

            &.open {
              transform: rotate(180deg);
              transition: .5s; } } } }

      .replies-block {
        background: #25304B;
        padding: 1.5rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
        display: none;

        .replies {
          max-height: 25rem;
          overflow-y: auto;

          .reply {
            font-size: 1.2rem;
            margin-bottom: 1rem;
            border-bottom: $line-divider;
            padding-bottom: 1rem;
            padding-right: 1rem;
            padding-left: 1rem;

            .header {
              display: flex;
              justify-content: space-between;
              margin-bottom: 1rem;
              font-size: inherit;

              span {
                font-size: inherit; } }

            .content {
              margin-bottom: 1rem;
              word-break: break-all; }

            .footer {
              font-size: 1.2rem;
              display: flex;
              justify-content: flex-end;
              align-items: center; } } }

        .add-reply-block {
          display: none; } } } } }

.mention-users {
  &__list {
    width: 20rem;
    z-index: 2;
    height: 15rem;
    overflow: auto;
    position: fixed;
    display: none;

    &--item {
      background: $item-blue;
      padding: 1rem;

      &:hover {
        cursor: pointer;
        background: $item-dark-blue; }

      &:not(:last-of-type) {
        border-bottom: 0.1rem solid $item-dark-blue; } }

    &.active {
      display: block; } } }

.js-reason-type-text {
  font-size: 10px !important; }
