.dropdown {
  width: 100%; }

.dropdown-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  min-width: 15rem;
  border-radius: 0;
  border: 0;
  border-bottom: .1rem solid $color-border;
  padding: 0.5rem;
  background-color: $color-dark-blue-100;

  & > * {
    pointer-events: none; }

  &:hover {
    background-color: $color-dark-blue-100;
    box-shadow: none;
    border-bottom: .1rem solid $color-border; }

  .dropdown-item {
    &:hover {
      background-color: transparent;
      color: #fff; } }

  .dropdown-item-full {
    width: 100%;
    &:hover {
      background-color: transparent;
      color: #fff; } } }

.dropdown-menu {
  background-color: $dark-background-color;
  min-width: 100%; }

.dropdown-item {
  position: relative;
  color: #fff;
  text-decoration: none;
  font-size: 1.2rem;
  padding-left: 3rem;
  width: 100%;
  overflow: hidden;
  background: transparent;
  -webkit-appearance: none;
  appearance: none;
  &:hover {
    background: rgba(255, 255, 255, 0.3); }


  &::before {
    content: '';
    background-color: #ffa500;
    width: .9rem;
    height: .9rem;
    border-radius: 50%;
    position: absolute;
    left: .5rem;
    top: .9rem; }

  &--hot {
    &::before {
      background-color: red; } }

  &--normal {
    &::before {
      background-color: grey; } }

  &--cold {
    &::before {
      background-color: lightblue; } }

  &--active {
    &::before {
      background-color: green; } }

  &--inactive {
    &::before {
      background-color: red; } } }

.section {
   .dropdown {
      width: 100%;
      padding-bottom: 0;
      height: 4.5rem;

      .tail {
         cursor: pointer;
         pointer-events: none;
         position: absolute; }

      .dropdown-toggle {
         background-color: transparent;
         width: 100%;
         box-shadow: none;
         color: #ffffff;
         border: 0;
         height: 100%;

         &:after {
            content: none; }

         .dropdown-item {
            position: none;
            padding: 0; } } } }

.dropdown-input-base {
  background: $dropdown-background;
  .select-label {
    .label-inner {
      font-size: 1.2rem !important; } } }

.input-base {
  &--width-200 {
    width: 20rem;
    flex: unset;

    .tail-select {
      width: 100%; } }

  &.no-label {
    .tail {
        right: 2rem;
        top: 1.2rem; } }

  &:not(.no-label) {
    .dropdown-toggle {
        padding: 1.8rem 0 1rem 1rem; }

    .tail {
        right: 2rem;
        top: 1.5rem; } }

  &.large-dropdown {
    min-width: 10rem;

    .dropdown-toggle {
        padding: 1rem 5rem 1rem 2rem; } } }

.dropdown {
  border-radius: 5px;

  &.grid__dropdown {
    .dropdown-toggle {
        background-color: $dark-background-color;
        &:after {
          content: none; } } }

  .dropdown-toggle {
    border-radius: 5px;
    border: 0;
    color: #ffffff;
    min-width: 1px; }

  .red-bullet {
    &:before {
        background-color: #F2323F; } }

  .green-bullet {
    &:before {
        background-color: #22B69E; } }

  .grey-bullet {
    &:before {
        background-color: #CCCCCC; } }

  .orange-bullet {
    &:before {
        background-color: #F29932; } } }

.dropdown-menu {
  background-color: $dark-background-color;
  padding: 0;
  border: 1px solid #374171;

  &--max-15 {
    &.show {
      max-height: 15rem; } }

  &.no-bullet {
    .dropdown-item {
        padding: 1.3rem 1rem;

        &:before {
          content: none; } } }


  .dropdown-item.no-bullet {
    padding: 1rem;

    &:before {
        content: none; } }

  .dropdown-item {
    color: #ffffff;
    line-height: 1rem;
    padding: 1rem;

    .icon {
      margin-right: 1rem;
      color: #5E77BC;
      font-size: 1.4rem; }

    &:hover, &:active {
      background-color: #2b3156;
      color: #ffffff;
      cursor: pointer; }

    &:not(:last-child) {
      border-bottom: 1px solid #374171; }

    &:last-child {
      border-radius: 0 0 5px 5px; }

    &:before {
      top: 1.5rem;
      left: 1.2rem; }

    &--header {
      &:hover, &:active {
        background-color: transparent;
        color: #ffffff;
        cursor: default; } } } }

.dropdown-toggle {
  .dropdown-item {
    &:not(.dropdown__bullet) {
      margin-right: 3.5rem; } } }

.dropdown .dropdown-toggle .dropdown-item.dropdown__bullet, .dropdown-menu  .dropdown__bullet {
  padding-left: 3.5rem;
  position: relative;
  align-items: center;

  &:before {
      postiion: absolute;
      top: 1.6rem; } }

.dropdown .dropdown-toggle .dropdown-item.dropdown__bullet {
  &:before {
      postiion: absolute;
      top: 0.5rem; } }

.dropdown {
  .btn {
    border: 1px solid #374171; }
  &.show .dropdown-toggle {
    border-radius: 5px 5px 0 0; }

  .dropdown-menu {
    border-radius: 0 0 5px 5px;
    margin: 0;

    &.show {
        top: -2px !important; }

    .dropdown-item {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem; } } }

.grid__dropdown-menu {
  min-width: 0;
  z-index: 9999;

  a {
    font-size: 1.2rem; } }

// Custom to POD modal
#upload-pod-modal,
#upload-pod-goods-delivered-modal {
  .dropdown-menu {
    &.show {
      max-height: 15rem; } } }

body .grid__dropdown-menu.js-dropdown-menu {
  max-height: 50vh;
  overflow: auto; }

.reason-code-dropdown-container {
  .dropdown-menu {
    left: 0 !important;
    tranform: none !important;
    width: 100% !important; } }

.header-dropdown {
  min-width: 17rem;
  margin-right: 2rem; }
